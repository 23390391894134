
import { useEffect, useState } from 'react';
import HikeModel from '../../models/HikeModel';
import RegionModel from '../../models/RegionModel';
import OutdoorActivtyService from '../../services/OutdoorActivityService';
import Hikes from '../Hikes/Hikes';
import MapComponent, { IFeatureSource } from '../Map/Map';
import Title from '../Title/Title';
import './Region.css';
import { useHistory } from "react-router-dom";
import { useMediaQuery } from '@mui/material';
import React from 'react';
import StyledTabs from '../StyledTabs/StyledTabs';

interface RegionProps {
  region: RegionModel;
  scrollPosition: number | undefined;
}

function Region(props: RegionProps) {

    const region: RegionModel = props.region;

    const [hikes, setHikes] = useState<HikeModel[]>([]);
    const [tabIndex, setTabIndex] = React.useState(0);

    const history = useHistory();

    const isSmallWidth = useMediaQuery('(max-width: 850px)');

    useEffect(() => {
        if(region.hikes) {
            setHikes(region.hikes);
            return;
        }
        
        let outdoorActivityService = new OutdoorActivtyService();
        outdoorActivityService.fetchHikes(region.id)
            .then(hikes => {
                hikes = hikes.sort((h1,h2) => h1.origin < h2.origin ? -1 : 1);
                region.hikes = hikes;
                setHikes(hikes);
            });
    }, [region]);

    const featureSources: IFeatureSource[] = hikes.filter((hike: HikeModel) => 
        hike.geometry).map(hike => ({ id: hike.id, geometry: hike.geometry}));

    const onGpxTrackSelected = (id: string): void => {
        const selectedHike = hikes.find(hike => hike.id === id);
        if(selectedHike) {
            history.push(`/hike/${selectedHike.id}`, selectedHike);
        }        
    };

    const handleChange = (event: any, newValue: any) => {
        setTabIndex(newValue);
    };

    return (
        <>
            <Title text={`Wander-Region ${region.name}`}/>
            <div className="regionHikesComponent" key="regionComponent">
            {
                isSmallWidth &&
                    <StyledTabs tabLabels={["Karte", "Liste"]} onChange={handleChange} />
            }
            {
                (hikes.length > 0 && (!isSmallWidth || (isSmallWidth && tabIndex === 0))) && 
                    <div key="mapContainer" className="mapContainer">
                        <MapComponent key="MapComponent" 
                            featureSources={featureSources}
                            onGpxTrackSelected={onGpxTrackSelected}></MapComponent>
                    </div>
            }
            {
                (hikes.length > 0  && (!isSmallWidth || (isSmallWidth && tabIndex === 1))) &&
                    <div key="hikeListContainer" className="hikeListContainer">
                        <Hikes hikes={hikes} 
                            scrollPosition={props.scrollPosition} />
                    </div>
            }
            </div>
        </>
    );
}

export default Region;