import { useEffect } from 'react';
import 'typeface-roboto';
import HikeModel from '../../models/HikeModel';
import HikesPreview from '../HikesPreview/HikesPreview';
import './Hikes.css';

interface HikesProps {
  hikes: HikeModel[];
  scrollPosition: number | undefined;
}

function Hikes(props: HikesProps) {

  const hikes = props.hikes;

  useEffect(() => {
    updateScrollPosition(props.scrollPosition || 0);
  }, [props.scrollPosition]);

  return (
    <>
      <div className="hikesComponent">
        <div className="hikesContainer">
          <HikesPreview hikes={hikes}></HikesPreview>
        </div>
      </div>
    </>
  )
}

function updateScrollPosition(scrollPosition: number) {
  let element = document.querySelector('.hikesComponent');
  if(element != null)
      element.scroll(0, scrollPosition);
}

export default Hikes;