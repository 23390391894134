import './App.css';
import { Route, Switch } from 'react-router-dom';
import Breadcrumbs from './components/Breadcrumbs/Breadcrumbs';
import BreadcrumbsConfigItemModel from './models/BreadcrumbsConfigItemModel';
import HikesApp from './components/HikesApp/HikesApp';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { BiInfoCircle } from "react-icons/bi";

function App() {

  const breadcrumbsConfigItemModels: BreadcrumbsConfigItemModel[] = [
    new BreadcrumbsConfigItemModel("/", (state: any) => { return "Wander-Regionen (Home)"; }),
    new BreadcrumbsConfigItemModel("/region/:regionId", 
      (state: any) => { if(state == null) return "Wander-Region"; return `Region ${state.name}`; }),
    new BreadcrumbsConfigItemModel("/hike/:hikeId", 
      (state: any) => { if(state == null) return "Wanderung"; return `Wanderung ${state.origin} - ${state.destination}`; })
  ];

  const appVersion = process.env.REACT_APP_VERSION;
  console.log(appVersion);

  return (
    <div id="appContainer">
      <div id="appHeader"> 
        <Breadcrumbs breadcrumbsConfig={breadcrumbsConfigItemModels} />
        <Tooltip title={appVersion}>
          <IconButton>
            <BiInfoCircle />
          </IconButton>
        </Tooltip>
      </div>
      <Switch>
        <Route path="/" component={HikesApp} exact></Route>
        <Route path="/region/:regionId" component={HikesApp}></Route>
        <Route path="/hike/:hikeId" component={HikesApp}></Route>
      </Switch>
    </div>
  );
}

export default App;