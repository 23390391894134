export default class BreadcrumbsConfigItemModel {
    private _path: string;
    private _titleExpression: (state: any) => string;

    constructor(path: string,
                titleExpression: (state: any) => string) {
        this._path = path;
        this._titleExpression = titleExpression;
    }

    public get path(): string {
        return this._path;
    }

    public get titleExpression(): (state: any) => string {
        return this._titleExpression;
    }    
  }