import HikeModel from "../models/HikeModel";
import PictureModel from "../models/PictureModel";
import RegionModel from "../models/RegionModel";

export default class OutdoorActivtyService {
  
	private baseUrl: string;

	constructor() {
		if(window.location.port === "3000") {
			this.baseUrl = "https://" + window.location.hostname + ":44335";
		} else {
			this.baseUrl = window.location.protocol + "//" + window.location.hostname + ":" +  window.location.port + "/api";
		}    
	}

	public async fetchRegions(): Promise<RegionModel[]> {
		const url = `${this.baseUrl}/regions`;
		const response = await fetch(url);
		const regions: RegionModel[] = await response.json();
		return regions;
	}

	public async fetchRegion(regionId: string): Promise<RegionModel> {
		const url = `${this.baseUrl}/region?regionId=${regionId}`;
		const response = await fetch(url);
		const region: RegionModel = await response.json();
		return region;
	}

	public async fetchHikes(regionId: string) : Promise<HikeModel[]> {
		const url = `${this.baseUrl}/hikes?regionId=${regionId}`;
		const response = await fetch(url);
		const hikes: HikeModel[] = await response.json();
		return hikes;
	}

	public async fetchHike(hikeId: string) : Promise<HikeModel> {
		const url = `${this.baseUrl}/hike?hikeId=${hikeId}`;
		const response = await fetch(url);
		const hike: HikeModel = await response.json();
		return hike;
	}

	public async fetchPictures(hikeId: string) : Promise<PictureModel[]> {
		const url = `${this.baseUrl}/pictures?hikeId=${hikeId}`;
		const response = await fetch(url);
		const pictures: PictureModel[] = await response.json();
		return pictures;
	}
}
