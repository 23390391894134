import { Link } from 'react-router-dom';
import BreadcrumbsItemModel from '../../models/BreadcrumbsItemModel';
import './BreadcrumbsItem.css';

interface BreadcrumbsItemProps {
    breadcrumbsItem: BreadcrumbsItemModel;
}

function BreadcrumbsItem(props: BreadcrumbsItemProps) {
    
    const breadcrumbsItem: BreadcrumbsItemModel = props.breadcrumbsItem;

    return (
        <div className="breadcrumbsItemComponent">
            <Link  to={{pathname: breadcrumbsItem.pathname, state: breadcrumbsItem.state}}>
                {breadcrumbsItem.title}
            </Link>
        </div>
    );
}

export default BreadcrumbsItem;
