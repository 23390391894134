import { Component } from 'react';
import './Map.css';
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import GeoJSON from 'ol/format/GeoJSON';
import WMTSTileGrid from 'ol/tilegrid/WMTS';
import WMTS from 'ol/source/WMTS';
import 'ol/ol.css';
import VectorLayer from 'ol/layer/Vector';
import Tile from 'ol/layer/Tile';
import {Style} from 'ol/style';
import Projection from 'ol/proj/Projection';
import proj4 from 'proj4';
import {register} from 'ol/proj/proj4';
import {buffer, Extent} from 'ol/extent';
import { Collection, Feature } from 'ol';
import { isMobile } from 'react-device-detect';
import {DragPan, MouseWheelZoom, defaults, Interaction} from 'ol/interaction';
import {platformModifierKeyOnly, click} from 'ol/events/condition';
import Select from 'ol/interaction/Select';
import { LayerCreator } from './LayerCreator';

export interface IFeatureSource {
  id: string;
  geometry: string;
}

export interface MapProps {
  featureSources: IFeatureSource[],
  onGpxTrackSelected?: (id: string) => void;
}

interface MapState {
  featureSources: IFeatureSource[],
  map: any,
  onGpxTrackSelected?: (id: string) => void
}

export default class MapComponent extends Component<MapProps, MapState> {

  private readonly useSwisstopoWMTS: boolean = true;
  private readonly hoverFeatureWidth: number = 5;
  private readonly layerCreator: LayerCreator = new LayerCreator();

  constructor(props: MapProps) {
    super(props);
    this.state = {
      featureSources: props.featureSources,
      map: null,
      onGpxTrackSelected: props.onGpxTrackSelected
    }
  }  

  componentDidMount() {  
    if(this.useSwisstopoWMTS)
      this.initMapWithSwisstopoWMTS();    
    else
      this.initMapWithOSMTileLayer();
  }

  render() {
    return (
      <div id="map" className="map"></div>
    );
  }

  private initMapWithSwisstopoWMTS() {

    var self = this;
  
    proj4.defs('EPSG:21781',
      '+proj=somerc +lat_0=46.95240555555556 +lon_0=7.439583333333333 +k_0=1 ' +
      '+x_0=600000 +y_0=200000 +ellps=bessel ' +
      '+towgs84=660.077,13.551,369.344,2.484,1.783,2.939,5.66 +units=m +no_defs');
    register(proj4);

    var projection = new Projection({
      code: 'EPSG:21781',
      units: 'm'
    });

    const vectorLayer: VectorLayer = this.layerCreator.createVectorLayer(new GeoJSON(), 'EPSG:21781', this.state.featureSources);
    //const self = this;
    vectorLayer.getSource().on("addfeature", function(e) {
      let mapExtent: Extent = vectorLayer.getSource().getExtent();
      let bufferDistance: number = 500;
      let bufferedExtent: Extent = buffer(mapExtent, bufferDistance);
      self.state.map.getView().fit(bufferedExtent);
      self.state.map.getTargetElement().classList.remove('spinner');
    });

    let swissTopoTileLayer: Tile = this.createSwisstopoTileLayer();

    let interactions: Collection<Interaction> = defaults();

    if(isMobile) {
      interactions = defaults({dragPan: false, mouseWheelZoom: false}).extend([
        new DragPan({
          condition: function (event) {
            return this.getPointerCount() === 2 || platformModifierKeyOnly(event);
          },
        }),
        new MouseWheelZoom({
          condition: platformModifierKeyOnly,
        }),
      ]);
    }

    let map: Map = new Map({
      target: 'map',
      layers: [
        swissTopoTileLayer,
        vectorLayer
      ],
      view: new View({
        projection: projection, 
        center: [660557, 183337],
        zoom: 8
      }),
      interactions: interactions
    });

    map.getTargetElement().classList.add('spinner');

    let selected: Feature | null = null;

    const select = new Select({
      condition: click,
      style: null,
      filter: function(feature: any, layer: any): boolean {
        const isGpxLayer = layer === vectorLayer;
        return isGpxLayer;
      }
    } as any);

    map.addInteraction(select);

    select.on('select', function (e) {
      const features = e.target.getFeatures();
      if(features.getLength() > 0) {
        if(self.state.onGpxTrackSelected) {
          self.state.onGpxTrackSelected(features.getArray()[0].getId());
        }
      }      
    });

    map.on('pointermove', function (e) {
      if (selected !== null) {
        self.updateStyleWidth(selected as Feature, -self.hoverFeatureWidth);
        selected = null;
      }
    
      map.forEachFeatureAtPixel(e.pixel, function (feature) {
        if(feature == null) {
          return;
        }
        selected = feature as Feature;
        self.updateStyleWidth(selected, self.hoverFeatureWidth);        
        return true;
      });
    
      //TODO: Show tooltip
    });
    
    this.setState({
      map: map
    })
  }

  private updateStyleWidth(feature: Feature, delta: number) {
    const style = feature.getStyle() as Style;
    const stroke = style.getStroke();
    const width = stroke.getWidth();
    stroke.setWidth(width ? width + delta : 0);
    feature.setStyle(style);
  }

  private initMapWithOSMTileLayer() {
   
    let vectorLayer: VectorLayer = this.layerCreator.createVectorLayer(new GeoJSON(), 'EPSG:21781', this.state.featureSources);

    let osmTileLayer: TileLayer = this.createOSMTileLayer();

    let map: Map = new Map({
      target: 'map',
      layers: [
        osmTileLayer,
        vectorLayer
      ],
      view: new View({
        projection: 'EPSG:4326',
        center: [8.81375868804752826690673828125, 46.18289164267480373382568359375],
        zoom: 5
      })
    });
    
    this.setState({
      map: map
    })
  }

  private createSwisstopoTileLayer() : Tile {
    let wmtsSource: WMTS = this.createWMTSSource();
    let swisstopoTileLayer: Tile = new Tile({
      source: wmtsSource,
      opacity: 0.4
    });
    return swisstopoTileLayer;
  }

  private createWMTSSource() {
    var layerName = "ch.swisstopo.pixelkarte-farbe";
    var projectionCode = 'EPSG:21781';
    var projectionIdentifier = '21781';
    var url;
    var origin;
    var tileColRow;
    if(projectionIdentifier === '21781') { //LV03
      url = 'http://wmts.geo.admin.ch/1.0.0/{Layer}/default/';
      origin = [420000, 350000];
      tileColRow = '{TileRow}/{TileCol}';
    }
    else { //LV95
      url = 'http://wmts14.geo.admin.ch/1.0.0/{Layer}/default/';
      origin = [2420000,1350000];
      tileColRow = '{TileCol}/{TileRow}';
    }
    var RESOLUTIONS = [4000, 3750, 3500, 3250, 3000, 2750, 2500, 2250, 2000, 1750, 1500, 1250, 1000, 750, 650, 500, 250, 100, 50, 20, 10, 5, 2.5, 2, 1.5, 1, 0.5];
    var resolutions = RESOLUTIONS;
    var tileGrid = new WMTSTileGrid({
        origin: origin,
        resolutions: resolutions,
        matrixIds: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", 
                  "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26"]
    });
    var extension = 'jpeg';

    return new WMTS({
          crossOrigin: 'anonymous',
          attributions: '&copy; ' +
            '<a href="https://www.geo.admin.ch/de/home.html">' +
            'Pixelkarte / geo.admin.ch</a>',
          projection: new Projection({
            code: projectionCode,
            units: 'm'
          }),
          style: '',
          matrixSet: '',
          url: (url + 'current/' + projectionIdentifier + '/{TileMatrix}/'+tileColRow+'.').replace('http:', window.location.protocol) + extension,
          tileGrid: tileGrid,
          layer: layerName,
          requestEncoding: 'REST'
      });
  }

  private createOSMTileLayer(): TileLayer {
    let osmTileLayer: TileLayer = new TileLayer({
      source: new OSM()
    });
    return osmTileLayer;
  }
}
