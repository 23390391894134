import { useEffect } from 'react';
import RegionModel from '../../models/RegionModel';
import OutdoorActivtyService from '../../services/OutdoorActivityService';
import RegionPreview from '../RegionPreview/RegionPreview';
import Title from '../Title/Title';
import './Regions.css';

interface RegionsProps {
  scrollPosition: number | undefined;
  regions: RegionModel[];
  setRegions: React.Dispatch<React.SetStateAction<RegionModel[]>>;
}

function Regions(props: RegionsProps) {
  const { scrollPosition, regions, setRegions } = props;

  useEffect(() => {
    if(regions.length > 0) {
      return;
    }

    let outdoorActivityService = new OutdoorActivtyService();
    outdoorActivityService.fetchRegions().then(regions => {
      regions = regions.sort((r1,r2) => r1.name < r2.name ? -1 : 1);
      setRegions(regions);
    });
  });

  useEffect(() => {
    updateScrollPosition(props.scrollPosition || 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scrollPosition]);

  return (
    <>
      <Title text="Wander-Regionen"/>
      <div className="regionsComponent">
          <div className="regionsContainer">
            {(regions != null) &&
              regions.map((region: RegionModel) => 
                <RegionPreview key={region.id} region={region} />
              )
            }      
          </div>
      </div>
    </>
  );
}

function updateScrollPosition(scrollPosition: number) {
  let element = document.querySelector('.regionsComponent');
  if(element != null)
      element.scroll(0, scrollPosition);
}
   
export default Regions;