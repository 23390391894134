import BreadcrumbsConfigItemModel from "./BreadcrumbsConfigItemModel";

export default class BreadcrumbsItemModel {
    private _id: string;
    private _pathname: string;
    private _state: any;
    private _config: BreadcrumbsConfigItemModel;

    constructor(id: string, pathname: string, 
                state: any, config: BreadcrumbsConfigItemModel) {
        this._id = id;
        this._pathname = pathname;
        this._state = state;
        this._config = config;
    }

    public get id(): string {
        return this._id;
    }

    public get pathname(): string {
        return this._pathname;
    }

    public get title(): string {
        return this._config.titleExpression(this._state);
    }

    public get state(): any {
        return this._state;
    }
    
  }