import Feature from "ol/Feature";
import FeatureFormat from "ol/format/Feature";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import MultiLineString from 'ol/geom/MultiLineString';
import { Stroke, Style } from 'ol/style';
import { IFeatureSource } from './Map';

export default interface ILayerCreator {
    createVectorLayer(featureFormat: FeatureFormat, projection: string, featureSources: IFeatureSource[]): VectorLayer;
}

export class LayerCreator implements ILayerCreator {
    createVectorLayer(featureFormat: FeatureFormat, projection: string, featureSources: IFeatureSource[]): VectorLayer {
        let layerSource: VectorSource = this.createLayerSource(featureFormat, projection, featureSources);
        let layer: VectorLayer = new VectorLayer({
            source: layerSource
        });
        return layer;
    }

    private createLayerSource(featureFormat: FeatureFormat, featureProjection: string, featureSources: IFeatureSource[]): VectorSource {
        let gpxLayerSource: VectorSource = new VectorSource({
            format: featureFormat,
            loader: async function(extent, resolution, projection) {
                
                let allFeatures: Feature[] = [];
                var overallStartTime = performance.now();
                
                await Promise.all(featureSources.map(async featureSource => {
                
                    if(featureSource.geometry) {
                        const geoJSON = featureSource.geometry;
                        let features: Feature[] = featureFormat.readFeatures(geoJSON, {
                            featureProjection: projection,
                            dataProjection: 'EPSG:4326'
                        }) as Feature[];

                        if(featureProjection === 'EPSG:21781') {
                            features.forEach((feature: Feature) => {
                                feature.setId(featureSource.id);
                                var geometry = feature.getGeometry() as MultiLineString;
                                //geometry.intersectsExtent()
                                var coordinates = geometry.getCoordinates();
                                coordinates.forEach((coordinate: any) => {
                                    coordinate[1] = coordinate[1] - 57;
                                });
                                geometry.setCoordinates(coordinates);
                            });
                        }
                        allFeatures = allFeatures.concat(features);
                    }
                }));
                
                var overallEndTime = performance.now();
                console.log("load gpx files finished: " + (overallEndTime - overallStartTime));
                const interpolate = require('color-interpolate');
                let colormap = interpolate(['rgb(213,62,79)', 'rgb(50,136,189)']);
                gpxLayerSource.addFeatures(allFeatures);
                const interval = 1.0 / allFeatures.length;
                let current = 0;
                gpxLayerSource.forEachFeature(feature => {
                    let randomColor = `#${Math.floor(Math.random()*16777215).toString(16)}`;
                    if(allFeatures.length <= 20) {
                        randomColor = colormap(current);
                    } 
                    current = current + interval;
                    const style = new Style({
                        stroke: new Stroke({
                            color: randomColor,
                            width: 3
                        })
                    });
                    feature.setStyle(style);
                });
                // let mapExtent: Extent = gpxLayerSource.getExtent();
                // let bufferDistance: number = 0;
                // if(featureProjection === 'EPSG:21781') {
                //     bufferDistance = 500;
                // } else {
                //     bufferDistance = 0.01;
                // } 
                //let bufferedExtent: Extent = buffer(mapExtent, bufferDistance);
                //self.state.map.getView().fit(bufferedExtent);
                //self.state.map.getTargetElement().classList.remove('spinner');
            }
        });

        return gpxLayerSource;
    }
}