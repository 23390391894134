import { Link } from 'react-router-dom';
import HikeModel from '../../models/HikeModel';
import './HikePreview.css';
import { BiTrendingDown, BiTrendingUp } from "react-icons/bi";
import { GiPathDistance } from "react-icons/gi";

interface HikeProps {
  hike: HikeModel;
}

function HikePreview(props: HikeProps) {

    const hike: HikeModel = props.hike;

    const defaultPicture: string | undefined = 
        hike.defaultPicture 
        ? hike.defaultPicture.thumbnail !== ""  
            ? hike.defaultPicture.thumbnail 
            : hike.defaultPicture.resizedPicture
        : undefined;

    return (
        <Link to={{pathname:`/hike/${hike.id}`, state: hike}}>
            <div className="hikePreviewComponent" key={hike.id}>
                <p>{`${hike.origin} - ${hike.destination}`}</p> 
                <div className="hikesDescriptions">
                    <GiPathDistance color="black" />
                    <p>{`${hike.distance}km`}</p>
                    <BiTrendingUp color="black" />
                    <p>{`${hike.ascent}m`}</p>
                    <BiTrendingDown color="black" />
                    <p>{`${hike.descent}m`}</p>
                </div>                 
                { defaultPicture
                    ? <img className="hikeFavoritePreviewImage" 
                        src={defaultPicture}
                        alt={`${hike.origin} - ${hike.destination}`}/>
                    : <img className="hikeDefaultPreviewImage" 
                        src={process.env.PUBLIC_URL + '/Hike.png'} 
                        alt={`${hike.origin} - ${hike.destination}`}/>
                }
            </div>
        </Link>
    );
}

export default HikePreview;