import { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import BreadcrumbsConfigItemModel from '../../models/BreadcrumbsConfigItemModel';
import BreadcrumbsItemModel from '../../models/BreadcrumbsItemModel';
import BreadcrumbsItem from '../BreadcrumbsItem/BreadcrumbsItem';
import './Breadcrumbs.css';

interface BreadcrumbsProps {
    breadcrumbsConfig: BreadcrumbsConfigItemModel[];
}

function Breadcrumbs(props: BreadcrumbsProps) {
    
    const [breadcrumbsItems, setBreadcrumbsItems] = useState<BreadcrumbsItemModel[]>([]);

    const location = useLocation();
    const previousLocation: any = usePrevious(location);

    const breadcrumbsItemConfig: BreadcrumbsConfigItemModel = 
        props.breadcrumbsConfig.find(config => 
            config.path.substr(0, config.path.lastIndexOf("/") + 1)
            .includes(location.pathname.substr(0, location.pathname.lastIndexOf("/") + 1))) as BreadcrumbsConfigItemModel;
    useEffect(() => {
        if(previousLocation != null 
            && location.pathname === previousLocation.pathname)
            return;
        const existing = breadcrumbsItems.find(
            breadcrumbsItem => breadcrumbsItem.pathname === location.pathname);
        if(existing != null) {
            const remainingBreadcrumbsItems: BreadcrumbsItemModel[] = [];
            const index = breadcrumbsItems.indexOf(existing);
            let i = 0;
            breadcrumbsItems.forEach(item => {
                if(i <= index) {
                    remainingBreadcrumbsItems.push(item);
                }
                i++;
            });
            setBreadcrumbsItems(remainingBreadcrumbsItems);
        } else {
            let newBreadcrumbsItem = 
                new BreadcrumbsItemModel(String(location.key), location.pathname, 
                                         location.state, breadcrumbsItemConfig);
            if(breadcrumbsItems.length === 0
                && breadcrumbsItemConfig !== props.breadcrumbsConfig[0]) {                
                setBreadcrumbsItems([
                    ...breadcrumbsItems,
                    new BreadcrumbsItemModel('', props.breadcrumbsConfig[0].path, 
                                             null, props.breadcrumbsConfig[0]),
                    newBreadcrumbsItem
                ]);
            } else {
                setBreadcrumbsItems([
                    ...breadcrumbsItems,
                    newBreadcrumbsItem
                ]);
            }
        }       
    }, [previousLocation, location.pathname, 
        location.key, location.state, breadcrumbsItems, 
        breadcrumbsItemConfig, props.breadcrumbsConfig]);

    return (
        <div className="breadcrumbsComponent">
            {breadcrumbsItems.map((breadcrumbsItem: BreadcrumbsItemModel) => 
                <BreadcrumbsItem key={breadcrumbsItem.id} breadcrumbsItem={breadcrumbsItem} />
            )}
        </div>
    );
}

function usePrevious(value: any) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
} 

export default Breadcrumbs;