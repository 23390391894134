import { useEffect, useRef, useState } from 'react';
import {RouteComponentProps } from 'react-router-dom';
import HikeModel from '../../models/HikeModel';
import RegionModel from '../../models/RegionModel';
import Hike from '../Hike/Hike';
import Regions from '../Regions/Regions';
import OutdoorActivtyService from '../../services/OutdoorActivityService';
import Region from '../Region/Region';

enum HikesAppComponents {
    Regions,
    Region,
    Hike
}

function HikesApp(props: RouteComponentProps) {

    const outdoorActivityService = new OutdoorActivtyService();

    const hikesComponentScrollPosition = useRef<number>(0);
    const regionsComponentScrollPosition = useRef<number>(0);

    const [regions, setRegions] = useState<RegionModel[]>([]);

    const [region, setRegion] = useState<RegionModel>();
    let regionModel: RegionModel = props.location?.state as RegionModel ?? region;

    const [hike, setHike] = useState<HikeModel>();
    let hikeModel: HikeModel = props.location?.state as HikeModel ?? hike;

    let component: HikesAppComponents = determineActiveComponent(props.match.path);
    let previousComponent: HikesAppComponents | undefined = usePrevious(component);

    if(component === HikesAppComponents.Hike
        && previousComponent === HikesAppComponents.Region) {
        let element = document.querySelector('.hikesComponent');
        if(element !== null)
            hikesComponentScrollPosition.current = element.scrollTop;
    }

    if(component === HikesAppComponents.Region 
        && previousComponent === HikesAppComponents.Regions) {
        hikesComponentScrollPosition.current = 0;
        let element = document.querySelector('.regionsComponent');
        if(element !== null)
            regionsComponentScrollPosition.current = element.scrollTop;
    }

    useEffect(() => {
        if(component !== HikesAppComponents.Region || regionModel !== undefined) {
            return;
        }
        outdoorActivityService.fetchRegion((props.match.params as any)["regionId"])
            .then(region => setRegion(region)
        );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [component]);

    useEffect(() => {
        if(component !== HikesAppComponents.Hike || hikeModel !== undefined) {
            return;
        }
        outdoorActivityService.fetchHike((props.match.params as any)["hikeId"])
            .then(hike => setHike(hike));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [component]);

    return (
        <>
            {
                (component === HikesAppComponents.Regions) && 
                    <Regions scrollPosition={regionsComponentScrollPosition.current} 
                             regions={regions}
                             setRegions={setRegions}/>
            }
            {
                (component === HikesAppComponents.Region && regionModel !== undefined) &&
                    <Region region={regionModel} 
                            scrollPosition={hikesComponentScrollPosition.current} />
            }
            {
                (component === HikesAppComponents.Hike && hikeModel !== undefined) &&
                    <Hike hike={hikeModel}/>
            }
        </>
    );
}

function determineActiveComponent(path: string): HikesAppComponents {
    if(path.startsWith("/region/")) {
        return HikesAppComponents.Region;
    } else if(path.startsWith("/hike/")) {
        return HikesAppComponents.Hike;
    } else {
        return HikesAppComponents.Regions;
    }
}

function usePrevious(value: any) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
} 

export default HikesApp;