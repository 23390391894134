import HikeModel from '../../models/HikeModel';
import HikePreview from '../HikePreview/HikePreview';
import './HikesPreview.css';

interface HikesPreviewProps {
  hikes: HikeModel[];
}

function HikesPreview(props: HikesPreviewProps) {
 
  return (
    <>
      <div className="hikesPreviewComponent">
          <div className="hikesPreviewContainer">
            {(props.hikes != null) &&
              props.hikes.map((hike: HikeModel) => 
                <HikePreview key={hike.id} hike={hike} />
              )
            }      
          </div>
      </div>
    </>
  );
}

export default HikesPreview;