import { Tab, Tabs } from "@mui/material";
import React from "react";

export interface StyledTabsProps {
    tabLabels: string[];
    onChange: (event: any, newValue: any) => void;
}

function StyledTabs(props: StyledTabsProps) {

    const { tabLabels, onChange } = props;

    const [tabIndex, setTabIndex] = React.useState(0);

    const handleChange = (event: any, newValue: any) => {
        setTabIndex(newValue);
        onChange(event, newValue);
    }
   
    return (
        <div className="tabsContainer" key="regionComponent">
            <Tabs value={tabIndex} 
                onChange={handleChange}
                sx={{
                    marginBottom: '10px',
                    '& .MuiTabs-indicator': { 
                        backgroundColor: '#026475',
                        color: '#026475',
                    },
                    '& .MuiTabs-scroller': {
                        height: '25px'
                    },                                    
                    '& .MuiTab-root': {
                        fontFamily: 'Segoe UI',
                        color: '#026475',                                    
                        textTransform: 'none',          
                        fontWeight: 'bold',
                        height: '20px',
                        minHeight: '20px'
                    },
                    '& .MuiTab-root.Mui-selected': {
                        color: '#026475',
                    }                                
                }}>
                    {
                        tabLabels.map((tabLabel: string) => {
                            return (
                                <Tab label={tabLabel} {...a11yProps(0)} />
                            )
                        })
                    }
            </Tabs>
        </div>
    )
}

function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}
  
export default StyledTabs;