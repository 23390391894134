import ImageGallery from 'react-image-gallery';
import './PictureGallery.css';

export interface IPicture {
    original: string;
    thumbnail: string;
    description: string;
}

export interface PictureGalleryProps {
    pictures: IPicture[];
}

function PictureGallery(props: PictureGalleryProps) {
    const { pictures } = props;
    const images = pictures;

    return (
        <div className='ImageGalleryContainer'>
          <ImageGallery items={images} />
        </div>
    );
}

export default PictureGallery;