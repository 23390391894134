import './Hike.css';
import 'typeface-roboto';
import MapComponent from '../Map/Map';
import HikeModel from '../../models/HikeModel';
import Title from '../Title/Title';
import { useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PictureGallery, { IPicture } from '../PictureGallery/PictureGallery';
import StyledTabs from '../StyledTabs/StyledTabs';
import OutdoorActivtyService from '../../services/OutdoorActivityService';
import PictureModel from '../../models/PictureModel';

export interface HikeProps {
  hike: HikeModel;
}

function Hike(props: HikeProps) {
  const hike = props.hike;
  
  const [hikeTabIndex, setHikeTabIndex] = useState(0);
  const [hikeDetailsTabIndex, setHikeDetailsTabIndex] = useState(0);
  const [pictures, setPictures] = useState<IPicture[]>([]);

  const isSmallWidth = useMediaQuery('(max-width: 850px)');

  useEffect(() => {
    if(hike.pictures) {
      setPictures(prepareGalleryPictures(hike.pictures));
      return;
    }

    let outdoorActivityService = new OutdoorActivtyService();
    outdoorActivityService.fetchPictures(hike.id).then(pictures => {
      hike.pictures = pictures;
      const hikePictures = prepareGalleryPictures(pictures);
      setPictures(hikePictures);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hike]);

  const prepareGalleryPictures = (hikePictures: PictureModel[]): IPicture[] =>  {
    return hikePictures
      .sort((p1, p2) => 
        (p1.pictureName > p2.pictureName) ? 1 : ((p2.pictureName > p1.pictureName) ? -1 : 0))
      .map(picture => {
        return {
          original: picture.resizedPicture,
          thumbnail: picture.thumbnail,
          description: picture.description
        }
    });
  }

  const getHikeDetails = () => {
    return (
      <div key="HikeDetails" className="form">
        {getHikeDetailsFields()}
      </div>
    );
  }
  
  const getHikeDetailsFields = () => { 
    let hikeFields: Array<HikeField> = [ 
      new HikeField("Ausgangsort", hike.origin, false),
      new HikeField("Zielort", hike.destination, false),
      new HikeField("Distanz [km]", hike.distance, false),
      new HikeField("Aufstieg [m]", hike.ascent, false),
      new HikeField("Abstieg [m]", hike.descent, false),
      new HikeField("Beschreibung", hike.description, true),
      new HikeField("Anreise", hike.arrival, true),
      new HikeField("Rückreise", hike.returnTrip, true),
      new HikeField("Verpflegung", hike.provisions, true),
      new HikeField("Sehenswürdigkeiten", hike.sights, true),
      new HikeField("Kommentar", hike.comment, true),
      new HikeField("Fitness [1-10]", hike.fitness, false),
      new HikeField("Bewertung", hike.rating, false),
      new HikeField("Weitere Infos", hike.extraInformation, true),
    ];
  
    let elements: any = [];
  
    hikeFields.forEach((hikeField: HikeField) => {
      elements.push(
        <div className="formfield" key={hikeField.fieldName}>
          <div className="label" key="label">
            <label>{hikeField.fieldName}</label>
          </div>
          <div className="value" key="value"> 
            {getHikeField(hikeField)}
          </div>
        </div>
      );
    });
    return elements;
  }
  
  const getHikeField = (hikeField: HikeField) => {
    return (<label>{hikeField.fieldValue}</label>)
  }

  const handleHikeTabChange = (event: any, newValue: any) => {
    setHikeTabIndex(newValue);
  }

  const handleHikeDetailsTabChange = (event: any, newValue: any) => {
    setHikeDetailsTabIndex(newValue);
  }

  let tabs;
  if(isSmallWidth) {
    tabs = ["Karte", "Details"];
    
  } else {
    tabs = ["Details"];
  }

  if(pictures.length > 0) {
    tabs.push("Fotos");
  }

  return (
    <>
      <Title text={`Wanderung ${hike.origin} - ${hike.destination}`}/>
      <div className="hikeComponent" key="hikeComponent">
        {(isSmallWidth) &&
          <>
            <StyledTabs tabLabels={tabs} onChange={handleHikeTabChange} />           
            {(hikeTabIndex === 0 && hike.geometry) &&
              <div key="mapContainer" className="mapContainer">
                <MapComponent key="MapComponent" 
                  featureSources={[{ id: hike.id, geometry: hike.geometry }]}></MapComponent>
              </div>
            }
            {(hikeTabIndex === 1) &&
              <div key="hikeDetailContainer" className="hikeDetailsContainer">
                {getHikeDetails()}
              </div>
            }
            {(hikeTabIndex === 2) &&
              <div key="hikePicturesContainer" className="hikePicturesContainer">
                <PictureGallery pictures={pictures} />
              </div>
            }
          </>
        }

        {(!isSmallWidth) &&
          <>
            {hike.geometry &&
              <div key="mapContainer" className="mapContainer">
                <MapComponent key="MapComponent" 
                  featureSources={[{ id: hike.id, geometry: hike.geometry }]}></MapComponent>
              </div>
            }
            <div key="hikeDetailContainer" className="hikeDetailsContainer">
              {tabs.length > 1 &&
                <div key="styledTabsContainer" className="styledTabsContainer">
                  <StyledTabs tabLabels={tabs} onChange={handleHikeDetailsTabChange} />
                </div>
              }
              {(hikeDetailsTabIndex === 0) &&
                getHikeDetails()
              }
              {(hikeDetailsTabIndex === 1) &&
                <PictureGallery pictures={pictures} />
              }
            </div>
          </>
        }
      </div>
    </>
  )
}

class HikeField {
  public fieldName: string;
  public fieldValue: any;
  public isTextArea: boolean;

  constructor(fieldName: string, fieldValue: any, isTextArea: boolean) {
    this.fieldName = fieldName;
    this.fieldValue = fieldValue;
    this.isTextArea = isTextArea;
  }
}

export default Hike;